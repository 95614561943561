.control-container {
  height: 15%;
  display: flex;
  justify-content: space-evenly;
  padding: 0.5rem;
}

.recorder-display {
  width: 50%;
  display: flex;
  align-items: center;
  /* font-size: 2rem; */
}

@keyframes animated-block {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.recording-indicator {
  width: 10px;
  height: 10px;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: #099fff;
  animation-name: animated-block;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.recording-time {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-button-container {
  width: 20px;
  display: grid;
  place-content: center;
  animation-name: animated-block;
  animation-duration: 2s;
}

.start-button,
.cancel-button {
  border: none;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.cancel-button:hover {
  color: #ff1744;
}

.cancel-button {
  width: 25px;
  height: 25px;
}

.start-button-container {
  width: 20px;
  display: grid;
  place-content: center;
}

.start-button:hover {
  color: #41295a;
  /* background-color: #f2ea02; */
  background-color: #eeeeee
}

.start-button {
  width: 40px;
  height: 40px;
}