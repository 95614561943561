/* global.css */

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* styles.css */
.border-light {
  border-radius: 8px;
  border: 1.5px solid rgba(82, 99, 125, 0.2);
}

.border-dark {
  border-radius: 8px;
  border: 1.5px solid rgba(82, 99, 125, 0.8);
}

.secondaryBackground {
  background-color: #002A6E;
  /* Secondary darkbackground color */
  color: white;
  /* Set foreground color to white */
}

.secondaryButton.MuiButton-root {
  background-color: #FCCD56;
  /* Secondary darkbackground color */
  color: #251C00;
  /* Set foreground color to white */
}

.secondaryButton.MuiButton-root:hover {
  background-color: #FCCD56;
  /* Secondary darkbackground color */
  color: #251C00;
  /* Set foreground color to white */
  opacity: 0.7;
  /* 30% opacity */
}
.control-container {
  height: 15%;
  display: flex;
  justify-content: space-evenly;
  padding: 0.5rem;
}

.recorder-display {
  width: 50%;
  display: flex;
  align-items: center;
  /* font-size: 2rem; */
}

@-webkit-keyframes animated-block {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animated-block {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.recording-indicator {
  width: 10px;
  height: 10px;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: #099fff;
  -webkit-animation-name: animated-block;
          animation-name: animated-block;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.recording-time {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-button-container {
  width: 20px;
  display: grid;
  place-content: center;
  -webkit-animation-name: animated-block;
          animation-name: animated-block;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.start-button,
.cancel-button {
  border: none;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.cancel-button:hover {
  color: #ff1744;
}

.cancel-button {
  width: 25px;
  height: 25px;
}

.start-button-container {
  width: 20px;
  display: grid;
  place-content: center;
}

.start-button:hover {
  color: #41295a;
  /* background-color: #f2ea02; */
  background-color: #eeeeee
}

.start-button {
  width: 40px;
  height: 40px;
}
/* Animations */
.hidden {
    display: none;
}

.rotated {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.section-fade {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.section-fade.visible {
    opacity: 1;
}

/* Styling */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background: linear-gradient(to bottom, white, #F2F7FF, white);
    overflow-x: hidden;
}

/* Header Styling */
/* .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 30px;
    background-color: #F2F7FF;
    border-bottom: 2px solid #E0E0E0;
}

.logo h1 {
    font-family: 'Inter', sans-serif;
    font-size: 35px;
    font-weight: 700;
    color: #0062FF;
    letter-spacing: -2px;
    margin-left: 50px;
}

.nav {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.nav-item {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-decoration: none;
    font-size: 18px;
    color: #0062FF;
    display: flex;
    align-items: center;
    padding: 10px; 
    border-radius: 20px;
    position: relative; 
    z-index: 10; 
}

.nav-item:hover {
    background-color: #CCE0FF;
}

.nav-icon-container {
    padding-top: 3px;
    margin-right: 10px;
}

.nav-icon {
    width: 24px;
    height: 24px;
}

.nav-text {
    display: inline-block;
}

.sign-in {
    margin-left: auto;
    margin-right: 20px;
} */

/* Button Styling */
/* .sign-in-btn {
    padding: 20px 40px;
    background-color: #F2F7FF;
    color: #0062FF;
    border: 1.5px solid #0062FF; 
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    transition: background-color 0.3s, border-color 0.3s;
    font-weight: 700;
} */

/* Hover effect */
/* .sign-in-btn:hover {
    background-color: #CCE0FF;
    color: #002A6E;
    border-color: #CCE0FF; 
} */

/* Section 1 Styling */
.intro-section {
    background-color: #F2F7FF;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 20px 40px;
    /* Reducing padding for text side */
    grid-gap: 20px;
    gap: 20px;
    /* Add gap between text and image sections */
}

.intro-section .text-side {
    flex: 0.8 1;
    /* Reduce the width allocated to the text section */
    padding-left: 60px;
    /* Ensure text starts 40px from the left */
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -90px;
    /* Move the text up */
}

.intro-section .text-side h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 56px;
    font-weight: 600;
    color: #002A6E;
    line-height: 126%;
    margin-bottom: 10px;
}

.intro-section .text-side p {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    margin-top: 5px;
    color: #52637D;
    line-height: 190%;
    font-weight: 400;
}

.intro-section .get-started-btn {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #1e1e1e;
    border: none;
    background-color: #FCCB56;
    border-radius: 12px;
    padding: 20px 30px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, -webkit-transform 0.2s;
    transition: background-color 0.3s, color 0.3s, transform 0.2s;
    transition: background-color 0.3s, color 0.3s, transform 0.2s, -webkit-transform 0.2s;
}

.intro-section .get-started-btn:hover {
    background-color: #faba24;
    color: white;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

/* Image Styling */
.intro-section .image-side {
    flex: 1.1 1;
    /* Allocate more space for the image */
    display: flex;
    justify-content: flex-end;
    /* Move the image to the far right */
    align-items: center;
    height: auto;
    margin-right: -60px;
}

.intro-section .image-side .person-image {
    width: 100%;
    /* Make the image fill the available space */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: contain;
}

.intro-section .blue-text {
    color: #0062FF;
}

@media (max-width: 768px) {
    .intro-section {
        flex-direction: column;
        /* Stack image and text vertically */
        align-items: center;
        /* Center align all items */
        padding: 20px;
        /* Adjust padding for mobile */
    }

    .intro-section .image-side {
        order: 1;
        /* Place image on top */
        width: 100%;
        /* Ensure the image fills the width */
        margin: 10px -10px 10px -10px;
        display: flex;
        /* Use flexbox */
        justify-content: center;
        /* Center horizontally */
        align-items: center;
        /* Center vertically */
    }

    .intro-section .image-side .person-image {
        max-width: 100%;
        /* Scale image for mobile */
        height: auto;
    }

    .intro-section .text-side {
        margin-top: -20px;
        order: 2;
        /* Place text below the image */
        width: 100%;
        /* Ensure text occupies full width */
        padding: 0;
        /* Remove side padding */
    }

    .intro-section .text-side h2 {
        font-size: 36px;
        /* Reduce font size for mobile */
        line-height: 126%;
        /* Adjust line height */
    }

    .intro-section .text-side p {
        font-size: 16px;
        /* Adjust paragraph font size for better readability */
        line-height: 190%;
        margin-top: 10px;
    }

    .intro-section .get-started-btn {
        font-size: 16px;
        /* Adjust button font size */
        padding: 24px 24px;
        /* Adjust button padding */
    }
}

/* Section 2 Styling */
.teachers-section {
    padding: 50px 40px;
    text-align: center;
}

.teachers-section-header h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 126%;
    color: #002A6E;
    margin-bottom: 10px;
}

.teachers-section-header p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #52637D;
    font-weight: 400;
    line-height: 190%;
    margin-bottom: 40px;
}

.teacher-profiles {
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
}

.teacher-profile {
    flex: 1 1;
    padding: 10px;
    border-radius: 12px;
    text-align: left;
}

.teacher-image {
    width: 100%;
    height: auto;
    border-radius: 16px;
    margin-bottom: 10px;
}

.teacher-profile h3 {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #002A6E;
    line-height: 126%;
    text-align: left;
}

.teacher-profile ul {
    padding: 0px 30px;
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 190%;
    color: #52637D;
    text-align: left;
}

.teacher-profile ul li {
    margin-bottom: 0px;
}

.see-more-dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* Align the content to the left */
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #0062FF;
    cursor: pointer;
    border: none;
    /* Remove button-like appearance */
    background: none;
    /* Transparent background */
    transition: color 0.3s;
    margin-top: 10px;
    /* Add some space between the profile description and the button */
}

.see-more-dropdown:hover {
    color: #0147b8;
}

.arrow-icon {
    width: 12px;
    height: auto;
    margin-left: 6px;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.additional-info {
    padding: 10px 20px 0px;
}

.university-logo {
    margin-top: 20px;
    width: 65px;
    height: auto;
    text-align: left;
}

@media (max-width: 768px) {
    .teachers-section {
        padding: 20px 20px;
    }

    .teachers-section-header h2 {
        font-size: 32px;
        line-height: 126%;
        padding: 20px;
    }

    .teachers-section-header p {
        font-size: 16px;
        line-height: 190%;
    }

    .teacher-profiles {
        flex-direction: column;
        /* Stack items vertically */
        grid-gap: 20px;
        gap: 20px;
        /* Maintain spacing between profiles */
    }

    .teacher-profile {
        padding: 10px;
        border-radius: 12px;
        text-align: left;
        margin-bottom: 5px;
    }

    .teacher-image {
        display: flex;
        /* Use flexbox */
        justify-content: center;
        /* Center horizontally */
        align-items: center;
        /* Center vertically */
        width: 100%;
        /* Adjust image size for mobile */
    }

    .teacher-profile ul {
        font-size: 16px;
        line-height: 190%;
        padding: 10px 20px 10px;
    }

    .see-more-dropdown {
        font-size: 16px;
        letter-spacing: -2.5%;
    }

    .university-logo {
        margin: 10px auto 0;
        /* Center-align logos and add spacing */
    }
}

/* Section 3 Styling */
.hurdles-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 60px 40px;
    grid-gap: 100px;
    gap: 100px;
}

/* Left Section Styling */
.hurdles-left {
    margin-top: 20px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

/* Section Header Styling */
.hurdles-left h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 42px;
    font-weight: 700;
    color: #002A6E;
    margin-bottom: 20px;
    line-height: 1.3;
    display: flex;
    align-items: center;
}

.hurdles-icon {
    width: 55px;
    height: 55px;
    margin-right: 20px;
}

/* Individual Hurdle Styling */
.hurdle {
    margin-bottom: 30px;
}

.hurdle-header {
    display: flex;
    align-items: center;
    /* Ensures vertical alignment of icon and text */
    margin-bottom: 10px;
    /* Adds spacing between h3 and p */
}

.hurdle-icon img {
    width: 40px;
    /* Adjust size as needed */
    height: 40px;
    margin-right: 15px;
    /* Space between icon and h3 */
    vertical-align: middle;
    /* Ensures the icon aligns vertically with text */
}

.hurdle-header h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #002A6E;
    margin: 0;
    line-height: 1.3;
    display: inline-block;
    /* Prevents extra spacing issues */
}

.hurdle p {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: #52637D;
    line-height: 1.8;
    font-weight: 400;
    margin: 0;
    /* Remove default margin for tight alignment */
    padding-left: 55px;
    /* Align p text with h3 by accounting for the icon size */
}

/* Right Section Styling */
.hurdles-right {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    margin-top: -20px;
}

/* Image Styling */
.spanning-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .hurdles-section {
        flex-direction: column;
        /* Stack sections vertically */
        align-items: center;
        /* Center-align the content */
        padding: 10px;
        /* Adjust padding for mobile */
    }

    .hurdles-left {
        margin-top: -50px;
        flex: 1 1;
        order: 2;
        text-align: left;
        padding: 0px 20px 0px;
    }

    .hurdles-right {
        flex: 1 1;
        order: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-top: 20px;
        /* Add space between image and text */
    }

    .spanning-image {
        width: 80%;
        /* Adjust image width for mobile */
        max-width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 8px;
    }

    .hurdles-left h2 {
        font-size: 32px;
        /* Reduce font size for mobile */
        line-height: 126%;
        margin-left: -50px;
    }

    .hurdle-header h3 {
        font-size: 20px;
        /* Reduce header font size for mobile */
        line-height: 126%;
    }

    .hurdle p {
        font-size: 14px;
        /* Adjust paragraph font size */
        line-height: 190%;
        padding-left: 60px;
        /* Remove left padding for mobile */
    }
}

/* Section 4 Styling */
.why-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 60px 40px;
    grid-gap: 100px;
    gap: 100px;
}

/* Left Section Styling */
.why-left {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    margin-top: -20px;
}

/* Image Styling */
.spanning-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

/* Right Section Styling */
.why-right {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

/* Section Header Styling */
.why-right h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 42px;
    font-weight: 700;
    color: #002A6E;
    margin-bottom: 20px;
    line-height: 1.3;
    display: flex;
    align-items: center;
}

.why-icon {
    width: 55px;
    height: 55px;
    margin-right: 10px;
}

/* Individual Why Items */
.why {
    margin-bottom: 30px;
}

/* Why Header */
.why-header {
    display: flex;
    align-items: center;
    /* Ensures icon and text alignment */
    margin-bottom: 10px;
    /* Adds space between heading and paragraph */
}

.why-header .why-icon img {
    width: 40px;
    /* Icon width consistent with hurdles */
    height: 40px;
    /* Icon height consistent with hurdles */
    margin-right: 15px;
    /* Space between icon and heading */
    position: relative;
    top: 6px;
    vertical-align: middle;
}

.why-header h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #002A6E;
    margin: 0;
    line-height: 1.3;
}

/* Why Paragraph */
.why p {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: #52637D;
    line-height: 1.8;
    font-weight: 400;
    margin: 0;
    /* Remove default margin */
    padding-left: 65px;
    /* Align with the heading by accounting for icon size and spacing */
}

@media (max-width: 768px) {
    .why-section {
        flex-direction: column;
        /* Stack sections vertically */
        align-items: center;
        /* Center-align the content */
        padding: 10px;
        /* Adjust padding for mobile */
    }

    .why-left {
        flex: 1 1;
        order: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-top: 20px;
        /* Add space between image and text */
    }

    .why-right {
        flex: 1 1;
        display: flex;
        order: 2;
        /* Place text below image */
        flex-direction: column;
        padding: 0px 20px 0px;
        margin-top: -50px;
        text-align: left;
        /* Center-align text */
    }

    .spanning-image {
        width: 80%;
        /* Adjust image width for mobile */
        max-width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 8px;
    }

    .why-right h2 {
        font-size: 32px;
        /* Reduce font size for mobile */
        line-height: 126%;
        margin-left: -60px;
    }

    .why-header h3 {
        font-size: 20px;
        /* Reduce header font size for mobile */
        line-height: 126%;
        margin-left: -5px;
    }

    .why p {
        font-size: 14px;
        /* Adjust paragraph font size */
        line-height: 190%;
        padding-left: 60px;
        /* Remove left padding for mobile */
    }
}

/* Section 5 Styling */
.oral-section {
    padding: 60px;
    margin-bottom: 50px;
}

.oral-section-header {
    text-align: center;
    margin-bottom: 10px;
}

.oral-section-header h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 42px;
    font-weight: 700;
    color: #002A6E;
    margin-bottom: 10px;
    line-height: 126%;
}

.oral-section-header p {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: #52637D;
    line-height: 190%;
    font-weight: 400;
}

.tile-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    padding: 10px;
    text-align: center;
}

.tile {
    width: 400px;
    /* Set a fixed width */
    height: auto;
    /* Set a fixed height to make it square */
    background-color: white;
    border-radius: 20px;
    padding: 20px 0 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    /* To prevent content from overflowing */
    text-align: center;
    align-items: center;
}

.tile img {
    width: 75px;
    height: auto;
    border-radius: 8px;
}

.tile h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #002A6E;
    margin-bottom: 5px;
    line-height: 126%;
}

.tile p {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: #52637D;
    line-height: 190%;
    margin-top: 5px;
    padding: 0 15px;
}

@media (max-width: 768px) {
    .oral-section-header {
        margin: 0px -40px;
    }

    .oral-section-header h2 {
        font-size: 32px;
        /* Adjust the font size for mobile */
        line-height: 126%;
    }

    .oral-section-header p {
        font-size: 16px;
        /* Adjust the paragraph font size for mobile */
        line-height: 190%;
    }

    .tile-section {
        flex-direction: column;
        /* Stack tiles vertically on smaller screens */
        align-items: center;
        /* Center-align the tiles */
        grid-gap: 20px;
        gap: 20px;
        /* Reduce gap between tiles */
        padding: 10px;
    }

    .tile {
        width: 120%;
        /* Make tiles take up more space on mobile */
        padding: 24px 15px;
        /* Adjust padding for mobile */
        max-width: 600px;
    }

    .tile img {
        width: 75px;
        /* Adjust image size for mobile */
        height: auto;
        padding: 10px;
    }

    .tile h3 {
        font-size: 20px;
        /* Reduce the size of the heading */
        line-height: 126%;
        margin-bottom: 16px;
    }

    .tile p {
        font-size: 16px;
        /* Reduce the size of the paragraph text */
        line-height: 190%;
    }
}

/* Banner Section Styling*/
.banner-section {
    margin: 30px 0 60px;
    padding: 0px 80px;
    /* Added padding to create space on the sides */
}

.banner-card {
    position: relative;
    width: calc(100%-80px);
    height: auto;
    background-image: url(/static/media/banner.0934df2a.png);
    /* Background Image */
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 60px;
    border-radius: 50px;
    overflow: hidden;
}

.banner-card-content {
    text-align: center;
    color: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
}

.banner-header {
    font-size: 42px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 126%;
}

.banner-subheader {
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 190%;
    margin-bottom: 20px;
}

/* Split Section */
.banner-split-section {
    display: flex;
    justify-content: space-between;
    grid-gap: 80px;
    gap: 80px;
}

.banner-split-section .split-left,
.split-right {
    width: 45%;
    margin: 0 10px;
    text-align: left;
}

.banner-split-section .split-left h3,
.split-right h3 {
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 126%;
    text-align: left;
}

.banner-split-section .split-left p,
.split-right p {
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 190%;
    text-align: left;
}

@media (max-width: 768px) {
    .banner-section {
        padding: 0px 20px;
        /* Reduce side padding to make it more responsive on smaller screens */
        margin: 20px 0;
        /* Reduce margin for smaller devices */
    }

    .banner-card {
        background-image: url(/static/media/banner_mobile.0935a218.png);
        padding: 20px;
        /* Add more padding inside the card for better spacing on small screens */
        margin-bottom: 30px;
        border-radius: 50px;
        /* Slightly reduce the border radius to keep the design consistent */
    }

    .banner-header {
        font-size: 32px;
        /* Reduce header font size for smaller screens */
        margin: 0px -22px 10px;
        line-height: 126%;
    }

    .banner-subheader {
        font-size: 16px;
        /* Adjust subheader font size */
        line-height: 190%;
        margin: 0px -20px 40px;
    }

    /* Split Section Adjustment */
    .banner-split-section {
        flex-direction: column;
        /* Stack the left and right sections vertically */
        grid-gap: 24px;
        gap: 24px;
        /* Reduce gap between the sections */
    }

    .banner-split-section .split-left,
    .split-right {
        width: 100%;
        /* Make each section take up the full width */
        text-align: left;
        /* Center-align text for better readability on mobile */
    }

    .banner-split-section .split-left h3,
    .split-right h3 {
        font-size: 20px;
        /* Adjust heading size for mobile */
        line-height: 126%;
        margin-bottom: 16px;
        margin-left: -15px;
    }

    .banner-split-section .split-left p,
    .split-right p {
        font-size: 16px;
        /* Adjust paragraph font size */
        line-height: 190%;
        /* Increase line height for better readability */
        margin-left: -15px;
    }
}

/* Footer Styles */
.footer {
    display: flex;
    justify-content: space-between;
    padding: 60px 120px;
    background-color: #F2F7FF;
    margin: 0;
    /* Remove any default margin */
    box-sizing: border-box;
    /* Include padding and border in the width calculation */
    max-width: 100%;
    /* Prevent horizontal overflow */
    overflow: hidden;
    /* Hide any overflow */
}

/* Footer Left Section - Larger Width */
.footer-left {
    width: 20%;
    /* Increased width for left section */
    margin: 0;
    /* Remove any default margin */
    padding: 0;
    /* Remove any default padding */
    box-sizing: border-box;
    /* Include padding in width calculation */
    margin-left: -30px;
}

/* Footer Brand Styling */
.footer-brand h1,
.footer-brand p {
    margin: 0;
    /* Remove default margin */
    padding: 0;
    /* Remove any padding */
}

.footer-brand h1 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 35px;
    color: #0062FF;
    line-height: 150%;
    letter-spacing: -2%;
}

.footer-brand p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 190%;
    font-weight: 400;
    color: #52637D;
}

/* Social Icons */
.footer-social {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 10px;
}

.social-icon-img {
    height: 24px;
    width: auto;
    object-fit: contain;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.social-icon-img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

/* Footer Right Section - Larger Width */
.footer-right {
    margin-top: -20px;
    margin-right: -40px;
    width: 70%;
    /* Increased width for right section */
    display: flex;
    justify-content: space-between;
    grid-gap: 40px;
    gap: 40px;
    box-sizing: border-box;
    /* Include padding in width calculation */
}

/* Footer Columns */
.footer-column h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 30px;
    color: #002A6E;
    line-height: 126%;
}

.footer-column ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-column ul li {
    margin-bottom: 10px;
}

.footer-column ul li a {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #52637D;
    text-decoration: none;
    line-height: 190%;
}

.footer-column ul li a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .footer {
        padding: 40px 20px;
        /* Reduced padding for better spacing on smaller screens */
        flex-direction: column;
        /* Stack footer sections vertically */
        align-items: center;
        /* Center the content */
    }

    /* Footer Left Section */
    .footer-left {
        width: 100%;
        /* Take full width on mobile */
        margin-left: 0;
        /* Reset margin */
        margin-bottom: 30px;
        text-align: center;
        /* Center align the brand section */
        padding: 10px 0;
        /* Add some padding for spacing */
    }

    /* Footer Brand Styling */
    .footer-brand h1 {
        font-size: 32px;
        /* Reduce font size for smaller screens */
        line-height: 150%;
        letter-spacing: -2%;
        margin-bottom: 8px;
    }

    .footer-brand p {
        font-size: 14px;
        /* Adjust font size for smaller screens */
        line-height: 190%;
        margin-bottom: 24px;
    }

    /* Social Icons */
    .footer-social {
        grid-gap: 10px;
        gap: 10px;
        /* Reduce gap between social icons */
        justify-content: center;
        /* Center the icons */
    }

    /* Footer Right Section */
    .footer-right {
        width: 100%;
        /* Full width on mobile */
        display: grid;
        /* Use grid for layout */
        grid-template-columns: 1fr 1fr;
        /* Two equal-width columns */
        grid-gap: 24px;
        /* Add space between items */
        align-items: start;
        /* Align items to the top of the grid */
        margin-top: 20px;
        margin-left: -10px;
    }

    /* Footer Columns */
    .footer-column {
        text-align: left;
        /* Align content to the left */
        width: 100%;
        /* Ensure consistent column width */
    }

    .footer-column h3 {
        font-size: 16px;
        /* Reduce font size for section headers */
        line-height: 126%;
        margin-bottom: 15px;
        /* Reduce margin between heading and list */
    }

    .footer-column ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    .footer-column ul li {
        margin-bottom: 8px;
        /* Reduce space between list items */
    }

    .footer-column ul li a {
        font-size: 14px;
        /* Adjust font size for links */
        line-height: 190%;
        text-decoration: none;
        /* Remove underline for links */
    }

    .footer-column ul li a:hover {
        text-decoration: underline;
        /* Add underline on hover */
    }

}
