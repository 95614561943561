/* global.css */

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* styles.css */
.border-light {
  border-radius: 8px;
  border: 1.5px solid rgba(82, 99, 125, 0.2);
}

.border-dark {
  border-radius: 8px;
  border: 1.5px solid rgba(82, 99, 125, 0.8);
}

.secondaryBackground {
  background-color: #002A6E;
  /* Secondary darkbackground color */
  color: white;
  /* Set foreground color to white */
}

.secondaryButton.MuiButton-root {
  background-color: #FCCD56;
  /* Secondary darkbackground color */
  color: #251C00;
  /* Set foreground color to white */
}

.secondaryButton.MuiButton-root:hover {
  background-color: #FCCD56;
  /* Secondary darkbackground color */
  color: #251C00;
  /* Set foreground color to white */
  opacity: 0.7;
  /* 30% opacity */
}